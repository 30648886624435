<template>
  <div class="main">
    <div class="container item-center">
      <v-row>
        <v-col
          cols="12"
          style="display: flex; justify-content: center; align-items: center"
          ><h1>Mezcalizate</h1></v-col
        >
        <v-col xs="6" sm="6" md="6" lg="6" xl="6">
          <v-img width="100%" class="img" src="../assets/nota2.jpg"></v-img
          ><br />
          <p style="text-align: justify">
            <a
              href="https://www.facebook.com/106779534438452/videos/295998131989329"
              target="_blank"
              ><u>#MagueyToken</u></a
            >
            te invita a ser parte de su primera cata digital, conoce acerca de
            esta cultura, su industria y trascendencia en compañía de Mezcal Don
            Agave. Descubre las sorpresas de nuestros invitados de lujo, los
            proyectos.
            <a
              href="https://www.facebook.com/106779534438452/videos/295998131989329"
              target="_blank"
              ><u>#Mezcalízate</u></a
            >
            <a
              href="https://www.facebook.com/106779534438452/videos/295998131989329"
              target="_blank"
              ><u>#ColectivoMezcalero</u></a
            >
            <br />
          </p>
          <v-btn
            text
            color="#55aa32"
            href="https://www.facebook.com/106779534438452/videos/295998131989329"
            target="_blank"
            >Ver más</v-btn
          >
        </v-col>
        <v-col xs="6" sm="6" md="6" lg="6" xl="6">
          <v-img width="100%" class="img" src="../assets/nota1.jpg"></v-img
          ><br />
          <p style="text-align: justify">
            <a
              href="https://www.facebook.com/106779534438452/videos/295998131989329"
              target="_blank"
              ><u>#Mezcalízate</u></a
            >
            está de vuelta con su segunda edición de la
            <a
              href="https://www.facebook.com/106779534438452/videos/295998131989329"
              target="_blank"
              ><u>#catamezcaleradigital</u></a
            >
            de MAGUEYTOKEN, acércate al mundo del mezcal, gastronomía y cultura
            y vive una experiencia única. Celebremos el mes de la Guelaguetza
            con el oro líquido de Oaxaca
          </p>
          <v-btn
            text
            color="#55aa32"
            href="https://www.facebook.com/106779534438452/videos/295998131989329"
            target="_blank"
            >Ver más</v-btn
          >
        </v-col>
        <!-- <v-col v-if="hideCol" cols="1"></v-col>
        <v-col xs="10" sm="12" md="5" lg="5" xl="5">
          <v-img class="img" src="../assets/nota2.jpg"></v-img><br />
          <p style="text-align: justify">
            <a
              href="https://www.facebook.com/106779534438452/videos/295998131989329"
              target="_blank"
              ><u>#MagueyToken</u></a
            >
            te invita a ser parte de su primera cata digital, conoce acerca de
            esta cultura, su industria y trascendencia en compañía de Mezcal Don
            Agave. Descubre las sorpresas de nuestros invitados de lujo, los
            proyectos.
            <a
              href="https://www.facebook.com/106779534438452/videos/295998131989329"
              target="_blank"
              ><u>#Mezcalízate</u></a
            >
            <a
              href="https://www.facebook.com/106779534438452/videos/295998131989329"
              target="_blank"
              ><u>#ColectivoMezcalero</u></a
            >
            <br />
          </p>
          <v-btn
            text
            color="#55aa32"
            href="https://www.facebook.com/106779534438452/videos/295998131989329"
            target="_blank"
            >Ver más</v-btn
          >
        </v-col>
        <v-col cols="4">
          <v-img class="img" src="../assets/nota1.jpg"></v-img><br />
          <p style="text-align: justify">
            <a
              href="https://www.facebook.com/106779534438452/videos/295998131989329"
              target="_blank"
              ><u>#Mezcalízate</u></a
            >
            está de vuelta con su segunda edición de la
            <a
              href="https://www.facebook.com/106779534438452/videos/295998131989329"
              target="_blank"
              ><u>#catamezcaleradigital</u></a
            >
            de MAGUEYTOKEN, acércate al mundo del mezcal, gastronomía y cultura
            y vive una experiencia única. Celebremos el mes de la Guelaguetza
            con el oro líquido de Oaxaca
          </p>
          <v-btn
            text
            color="#55aa32"
            href="https://www.facebook.com/106779534438452/videos/295998131989329"
            target="_blank"
            >Ver más</v-btn
          ></v-col
        ><v-col cols="2"></v-col> -->
      </v-row>
    </div>
  </div>
</template>
<script>
export default {
  mounted() {
    window.scrollTo(0, 0);
  },
};
</script>
<style scoped>
.img {
  border-radius: 10px;
  width: 90%;
}
u {
  color: #55aa32;
}
.container {
  margin: 15% 0 0 0;
}
.main {
  width: 100%;
  height: 120vh;
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;
}
h1 {
  color: #55aa32;
  letter-spacing: 14px;
  margin: 0% 0 0 0;
}
@media screen and (max-width: 600px) {
  .main {
    width: 100%;
    height: 185vh;
    background-color: white;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .container {
    margin: -15% 0 0 0;
  }
  h1 {
    color: #55aa32;
    letter-spacing: 14px;
    margin: 0% 0 0 0;
  }
}
</style>